* {
  margin: 0;
  padding: 0;
}

#footer {
  bottom: 0;
  width: 100%;
  /* height: 30%; */
  /* margin-top: 2vh; */
  text-align: center;
  color: rgb(252, 249, 228);
}

@media screen and (max-width: 900px) {
  #footer {
    height: auto;
  }
}
