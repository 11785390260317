.premier {
  height: 40vh;
  margin-right: 1.5vw;
  /*  background-color: darkslateblue; */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.fileUploadButton > input {
  display: none;
}

.align {
  height: 40vh;
}

.deuxieme {
  height: 19vh;
  width: 12vw;
  margin-bottom: 1vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.troisieme {
  margin-top: 1vh;

  height: 19vh;
  width: 12vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.quattrieme {
  margin-bottom: 1vh;

  height: 19vh;
  width: 12vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.cinquieme {
  margin-top: 1vh;

  height: 19vh;
  width: 12vw;
  margin-bottom: 1vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sixieme {
  height: 40vh;
  margin-right: 1vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media screen and (max-width: 900px) {
  .align {
    display: flex;
    flex-direction: row;
    height: auto;
    margin: auto;
  }

  .premier {
    width: 80vw;

    margin-left: 0;
    margin-bottom: 2vh;
  }

  .deuxieme {
    width: 38vw;
    margin: 0;
    margin-bottom: 2vh;
  }
  .troisieme {
    width: 38vw;
    margin: 0;
  }
  .quattrieme {
    width: 38vw;
    margin: 0;
    margin-bottom: 2vh;
    margin-left: 8vw;
  }
  .cinquieme {
    width: 38vw;
    margin: 0;

    margin-left: 8vw;
  }

  .sixieme {
    width: 80vw;

    margin-bottom: 2vh;
    margin-top: 2vh;
  }
}

/* .image__img {
  display: block;
  width: 100%;
} */

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.33s;
  border-radius: 5px;
}

.image__overlay--blur {
  backdrop-filter: blur(5px);
}

.image__overlay--primary {
  background: #00000034;
}

.image__overlay > * {
  transform: translateY(20px);
  transition: transform 0.33s;
}

.image__overlay:hover {
  opacity: 1;
}

.image__overlay:hover > * {
  transform: translateY(0);
}

.image__title {
  font-size: 2em;
  font-weight: bold;
}
